import React from 'react';
import Layout from '../layout/Layout';
import Seo from '../layout/Seo';

const terms = ({pageContext}) => {
    const {locale} = pageContext

    function Child({locale}){
      return(<>
      <Seo title="Error 404" description="Error 404" pathname="https:localhost"/>
  <section className="flex mt-9">
      <div className="flex-auto sm:flex-1"></div>
      <div className="flex sm:flex-1 justify-end sm:justify-start">
          <div className="flex flex-col bg-primary p-2 md:p-5 rounded-br-xl md:rounded-br-medium  text-white w-7/12 md:w-8/12 xl:w-6/12 sm:ml-2 mr-4">
              <span className="text-2xl leading-5 md:leading-7 lg:leading-normal md:text-3xl xl:text-4xl font-bold">
              OBRA YA
              </span>
              <span className="hidden md:block md:text-sm xl:text-xl font-bold mt-1">
              ¡Gracias por utilizar OBRA YA!
              </span>
          </div>
      </div>
  </section>

  <section className="p-5 md:p-2 font-serif text-secondary">
      <div className="flex flex-col md:flex-row self-center lg:max-w-3xl rounded-lg bg-white m-auto  mt-3 md:mt-6 text-center p-3">
          <div className="w-full mt-3 p-1 md:p-8 text-justify">
              <div className="flex flex-col">

                  <span className="text-primary uppercase font-bold text-xl font-serif">
                      Términos y Condiciones
                  </span>
                  <span className="text-secondary font-light text-sm font-serif my-5">
                  A través de los términos y condiciones de Obra YA, se regulará el uso de los servicios y productos de la marca, para los sitios web. Dichas condiciones constituyen un acuerdo vinculante entre cualquier usuario a cualquier título y OBRA YA. Se consideran aceptadas por parte del usuario cada vez que este acceda a cualquiera de los productos, por lo que se recomienda leer y entender todas las condiciones generales y demás documentos de términos de uso.

                      <br/><br />
                    <br />
                      <span className="text-primary uppercase font-bold text-xl font-serif">
                      A. INFORMACIÓN LEGAL
                        </span>
                        <br />
                    <br />
                      Al aceptar estas Condiciones Generales usted manifiesta que es mayor de edad, que tiene plena capacidad de obrar, que ha leído y comprendido las Condiciones de uso que a continuación se desarrollan y los términos legales que involucra como la política de privacidad o la política de cookies

                      <br/>
                      <br/>
                      El titular de este sitio web es OBRA YA, con domicilio en Bogotá, Colombia. OBRA YA pone a disposición del público en general el uso de su red de sitios web y otros productos con el objeto de dar a conocer los servicios y actividades que realiza OBRA YA, haciéndole saber que desde el momento en que usted acceda a esta Página Web o a cualquier otra de su red de sitios web y otros productos, queda sujeto a los siguientes Términos y Condiciones.

                      <br />
                      <br />
                      Esta información legal se completa con la Política de Protección de Datos Personales, con las Condiciones Generales de Uso del sitio web de OBRA YA y en su caso, con las Condiciones Particulares de cada producto o servicio recogidas en cualquiera de los sitios web de OBRA YA.
                    <br />
                    <br />
                    Todo el contenido de los sitios web, app y demás productos son propiedad de OBRA YA, con la excepción de las imágenes, sonidos e infografías utilizadas bajo licencia, quedando reservados todos los derechos. Se prohíbe su reproducción total o parcial. Cualquier utilización de los mismos contraria a las normas en materia de propiedad intelectual (derechos de autor, propiedad industrial y demás), será perseguida con arreglo a la legislación vigente en Colombia o en las jurisdicciones en las que dichas violaciones se concreten a elección de OBRA YA.
                    <br />
                    <br /><br />
                    <br />

                    <span className="text-primary uppercase font-bold text-xl font-serif">
                      B. INFORMACIÓN TÉCNICA
                        </span>
                        <br />
                    <br />
                    Los productos de OBRA YA están optimizados para ser visualizados en dispositivos nativos y en definiciones responsive (en definiciones menores aparecerán barras de desplazamiento tanto horizontal como verticalmente respectivamente).
                    <br />
                    <br />
                    Para la visualización del sitio web, se recomienda la utilización de Firefox 3.0 (o versiones posteriores) o Chrome 4.1 (o versiones posteriores). El uso de versiones anteriores de navegador, la utilización de un navegador no compatible o la desactivación de algunas propiedades, como la compatibilidad con Javascript o la desactivación de las cookies, puede reducir la funcionalidad del sitio.
                    <br />
                    <br />
                    Si se accede al sitio mediante un firewall (cortafuegos) o servidor proxy, asegúrese que permite la recepción de cookies.
                    <br />
                    <br />
                    <br />
                    <br />
                    <span className="text-primary uppercase font-bold text-xl font-serif">
                      C. TÉRMINOS Y CONDICIONES DE USO GENERAL
                        </span>
                        <br />
                    <br />
                    a. Condiciones Generales<br /><br />
                    Estas Condiciones Generales regulan el uso de los sitios web pertenecientes y gestionados por OBRA YA. Algunos de nuestros sitios web podrán recoger sus propias Condiciones Particulares que, según los casos completarán, modificarán o sustituirán las presentes Condiciones Generales. La utilización de cualquiera de los sitios web de OBRA YA supone la comprensión y aceptación plena de todas y cada una de las Condiciones Generales aquí recogidas así como de las Condiciones Particulares que, en su caso, se establezcan en algunos sitios web.
                    <br /><br />
                    b. Disponibilidad del Servicio       
                    <br /><br />
                    OBRA YA opera exclusivamente a través de su Sitio Web. El servicio se presta para Colombia, y se rige por las leyes colombianas, el Usuario podrá acceder desde cualquier lugar del mundo, atendiendo estos términos. 
OBRA YA se reserva el derecho de suspender, a su arbitrio, el acceso al Sitio Web sin previo aviso, así como de interrumpir el Servicio a cualquier Usuario que haga una utilización no ética, ofensiva, ilegal, o incorrecta de los contenidos o servicios que ofrece y/o contraría a los intereses de OBRA YA. <br />
OBRA YA, a través de su Sitio Web, facilita los procesos de compra poniendo en contacto una oferta y una demanda, pero no brinda ni pretende brindar dichos servicios, y no tiene responsabilidad alguna por los productos y/o servicios ofertados y/o prestados por los proveedores, incluidas, a título enunciativo y no taxativo, las garantías de idoneidad para un fin determinado, el cumplimiento de leyes, reglamentaciones o códigos.<br /><br />
                    i. Protección de Datos<br />
                    Para poder utilizar algunos de nuestros sitios web, app y demás productos de manera eficiente y segura, los usuarios deberán aportar ciertos datos dependiendo de su rol dentro del producto, entre ellos pueden figurar: su nombre y apellido, documento nacional de identidad, o cualquier otra identificación tributaria o previsional, profesión o actividad comercial, dirección, número de teléfono y cuenta de e-mail, sin los cuales se tornaría imposible brindar los servicios. Por eso se requiere que éstos sean verdaderos y exactos. Los datos recabados por los formularios correspondientes serán objeto de tratamiento directamente por OBRA YA y/o terceros.<br /><br />
                    ii. Gratuidad y no exigencia de suscripción
                    El acceso y/o utilización de nuestros sitios web de OBRA YA es gratuito, excepto para aquellos servicios que así se señalen en sus respectivas Condiciones Particulares. La completación del formulario de registro es obligatoria para acceder y disfrutar de los servicios ofrecidos en la página web. Así, para boletines informativos deberá facilitar su dirección de correo electrónico y para el uso de determinados servicios bajo registro deberá inscribirse como usuario registrado de la red de OBRA YA pudiéndose identificar siempre con el mismo usuario y contraseña, aprovechando con ello todas las posibilidades. El no facilitar los datos personales solicitados o el no aceptar la política de protección de datos supone la imposibilidad de suscribirse, registrarse o participar en cualquiera de las promociones en las que se soliciten datos de carácter personal.
iii. Veracidad y actualización de los datos<br />
El usuario registrado (cliente o profesional) o el suscriptor de boletines informativos es el único responsable de la veracidad y corrección de los datos incluidos. OBRA YA se exonera de cualquier responsabilidad al respecto.
<br />
iv. Correcta utilización de los sitios web.<br />
Todo aquel usuario que acceda a los sitios web y demás productos de OBRA YA, se compromete a la correcta utilización de cuantos servicios se encuentran en el mismo, teniendo en cuenta lo establecido en la legislación vigente que le fuera aplicable y a lo contenido en la Condiciones Generales y, en su caso, en las Condiciones Particulares publicadas en el sitio web en cada momento para cada producto o servicio, absteniéndose de realizar cualquier acto que pudiera ir en contra de los intereses de terceros, de la estructura y funcionamiento del sitio web de OBRA YA.<br />
v. Mayoría de edad<br />
Al aceptar estas Condiciones Generales el usuario manifiesta que es mayor de edad, que tiene plena capacidad de obrar, que ha leído y comprendido las Condiciones de uso, términos y condiciones que a continuación se desarrollan y demás términos legales como la política de privacidad o la política de cookies.<br />
vi. Comunicaciones comerciales <br />
La dirección de correo electrónico y demás datos personales facilitados por el usuario a OBRA YA podrán ser utilizados para el envío de comunicaciones comerciales o promocionales así como para actividades propias del objeto social de OBRA YA, bien sea a través del correo electrónico o de cualquier otro medio de comunicación equivalente, otorgado por el usuario consentimiento expreso para estas acciones.<br />

<br />
c. Opciones del usuario<br /><br />
En algunos de nuestros sitios web y demás productos pueden incorporar la posibilidad de opinar sobre el servicio/producto publicitado. OBRA YA no se hace responsable de las opiniones o comentarios realizadas por el usuario obligándose este último a no publicar comentarios o acciones: (i) difamatorios, inexactos, falsos, abusivos, injuriosos, obscenos, irreverentes, ofensivos, insultantes, de tácita o expresamente sexuales, amenazantes, acosadores, racistas, sexistas, discriminatorios por cualquier motivo, que atenten contra la moral, el orden público, los derechos fundamentales, las libertades públicas, el honor, la intimidad o la imagen de terceros y, en general, la normativa vigente; y/o ilegales o infractores de derechos de terceros; (ii) transmisores de correo basura, “SPAM”, mensajes con fines comerciales, o mailings masivos no solicitados, falsos, engañosos o que promuevan actividades ilegales o conducta grosera, amenazante, obscena, difamatoria o injuriosa; (iii) que suministren información práctica sobre actividades ilegales; (iv) que conlleven cualquier actividad publicitaria o comercial, incluso aunque no haya contraprestación monetaria; (v) que contengan datos de carácter personal sobre terceras personas que requieran el consentimiento del titular de los derechos. El usuario responderá frente a OBRA YA o frente a cualquier tercero, de cualesquiera daños y/o perjuicios que pudieran causarse como consecuencia del incumplimiento de dichas obligaciones. Los sitios web y demás productos pueden permitir la emisión y publicación de opiniones, positivas o negativas. OBRA YA no interviene las opiniones de los usuarios. No obstante, no publicará o eliminará las opiniones que no cumplan con las condiciones anteriores, no haciéndose responsable ni garantizando la veracidad y exactitud de las opiniones que puedan exponerse por los usuarios en su web y demás productos.<br />
Si OBRA YA detecta perfiles de usuarios y/o contenidos que no van acordes a lo establecido en las condiciones de uso del producto o cualquier acto ilegal, OBRA YA podrá borrar o modificar aquellos contenidos o perfiles en un plazo razonable desde que tenga conocimiento de ello.<br />
viii. Propiedad intelectual (derechos de autor, propiedad industrial y equivalentes)<br />
Toda la información contenida en la página web y demás productos, incluyendo, sin carácter limitativo, bases de datos, diseño gráfico, fotografías, logos, marcas, imágenes, audio, video, documentos, código fuente (HTML y Javascript), constituye una obra cuya propiedad intelectual pertenece a OBRA YA, o a terceros, sin que pueda entenderse que el uso o acceso a los sitios web y demás productos y/o a los servicios que se ofrecen, atribuya al usuario derecho alguno sobre esta información. El usuario se abstendrá de reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los servicios, a menos que se cuente con la autorización de OBRA YA; suprimir, eludir o manipular cualquier dato o signo identificativo de los derechos de OBRA YA o de sus titulares incorporados a los servicios así como los dispositivos técnicos de protección, y en particular, utilizar la información de cualquier clase obtenida a través del sitio web de OBRA YA o de los servicios, para remitir comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, enviar mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como a abstenerse de comercializar o divulgar de cualquier modo dicha información; obtener cualquier imagen, grabación, software y, en general, cualquier clase de material accesible a través de la página web y demás productos, mediante cualquier medio diferente a los que OBRA YA ponga a disposición de los usuarios.<br /><br />
d. Alcance del servicio: <br />
OBRA YA es una plataforma que facilita los procesos de demanda y oferta, en el mundo de la construcción, más no verifica la información de sus Usuarios y Proveedores, por tanto, es completa obligación de los usuarios licitadores o constructores y de los usuarios oferentes o proveedores realizar la debida diligencia de las personas involucradas, la veracidad de la información. <br /><br /><br /><br />
<span className="text-primary uppercase font-bold text-xl font-serif">
D. CONDICIONES PARTICULARES DE OBRA YA (RELACIÓN OBRA YA Y USUARIOS)

                        </span><br /><br />
                        Los productos OBRA YA se podrán prestar en diferentes medios, como plataforma móvil y sitios web. Dentro de las plataformas, en su mayoría móviles, los usuarios podrán tomar rol de “Profesional” y/o “Cliente” (en adelante de forma conjunta “usuarios”). Los clientes demandantes de servicios realizan la cotización por medio de OBRA YA, describen sus necesidades y reciben la cotización aproximada. Los prestadores de servicios (“profesionales”) acceden a las peticiones de los clientes y pueden ofertar sus servicios enviando mensajes vía chat whatsapp de OBRA YA o enviando cotizaciones para que sea el cliente quien decida a qué profesional contratar.<br /><br />
                        La responsabilidad de selección de un profesional y negociaciones de contratación de servicios o términos relativos a acuerdos entre cliente y profesional, es responsabilidad absoluta del cliente.<br /><br />
                        Para poder utilizar el Servicio es necesario el previo registro del Usuario, la aceptación de los presentes Términos de uso y la Política de privacidad y de cookies. Para la utilización de la plataforma, el usuario deberá completar un proceso de registro dónde podrá hacer el registro de diferentes formas:<br /><br />
Introduciendo de forma correcta y veraz los siguientes campos:<br />
- Nombre y apellido<br />
- Email<br />
- Ciudad Localidad<br />
OBRA YA ingresa estos datos a la plataforma para proceder con la entrega del servicio.<br /><br />











                  </span>
              </div>
          </div>

          
      </div>
      
  </section>
      </>)
    }
  return <Layout locale={locale}
  background="bg-image-about">
   {props => <Child {...props} />}
</Layout>;
};
export default terms